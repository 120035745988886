import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import HeroStatic from "../sections/peel-creative/HeroStaticAlt";
import TextBlock from "../sections/peel-creative/TextBlock";
import styled from "styled-components";
import { Title, Button, Section, Box, Text } from "../components/Core";

import HeroImage from "../assets/peel-creative/contact_hero.jpg"
import { Container, Row, Col } from "react-bootstrap";

const StyledRow = styled(Row)`
input{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9fc0c5;
  height: 40px;
}

textarea{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9fc0c5;
  height: 120px;
}


.label{
  padding-left: 10px;
  font-weight: bold;
  color: black;
  font-size: 18px;
}

form{
  width: 100%;
  max-width: 480px;
}

button{
  min-width: 60%;
  background-color: #9fc0c5;
  height: 40px;
  border-radius: 10px;
  text-align: right;
  margin-left: auto;
  border: none;
}

button p{
  color: white;
  font-size: 18px;
  font-weight: bold;

  padding-right: 25%;
}

`

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <HeroStatic image={HeroImage} />
        <Section>
        <Container>
        <StyledRow>
          <Title pb={5}>
            Contact
          </Title>
          <Col md={6}>
            <form method="POST" data-netlify="true" name="contact" action="/thankyou">
            <input type="hidden" name="form-name" value="contact"/>
            <input name="bot-field" style={{display: "none"}}/>

            <Text className='label' pb={1} name="full-name" type="text">Full Name</Text>
            <input type="text"/>
            <Text className='label' pt={4} pb={1} name="email" type="email">Email</Text>
            <input type="text"/>
            <Text className='label' pt={4} pb={1}>Message</Text>
            <textarea type="text" name="message"/>

            <Box pt={4} style={{display: "flex", justifyContent: "flex-end"}}>
            <button type="submit"><p>Submit</p></button>
            </Box>
            </form>

          </Col>
        </StyledRow>

        </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
